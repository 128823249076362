import { Component, OnInit, Pipe } from '@angular/core';

@Component({
  selector: 'app-hiring',
  templateUrl: './hiring.component.html',
  styleUrls: ['./hiring.component.css']
})
export class HiringComponent implements OnInit {
  pdfurl = '../../assets/files/resume.pdf'
  viewResume = false;

  allSkills = ['Programmer', 'Web Geek', 'Humorous']

  showResume($element): void {
    if (this.viewResume == false) {
      this.viewResume = true;
      setTimeout(function () {

        var element = document.getElementById('targetDiv');
        var headerOffset = 93;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
        var resumeText = document.getElementById('resumeText');
        resumeText.innerHTML = 'Hide Resume &nbsp;'
        //$element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }, 200);
    }
    else {
      var resumeText = document.getElementById('resumeText');
      resumeText.innerHTML = 'View Resume &nbsp;'
      this.viewResume = false
    }
  }


  constructor() {

  }

  ngOnInit() {
  }

}
