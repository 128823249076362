import { Component, OnInit, HostListener } from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'

@Component({
  selector: 'app-main-body',
  templateUrl: './main-body.component.html',
  styleUrls: ['./main-body.component.css'],
})
export class MainBodyComponent implements OnInit {
  bodyScroll = false;
  @HostListener('window:scroll', ['$event'])
    doSomething(event) {
      if(window.pageYOffset >= 50){
        this.bodyScroll = true;
      }
      else{
        this.bodyScroll = false;
      }
    }

  constructor() { }

  ngOnInit() {

  }

  get myStyles(): any {
    return {

        'color' :'Black',
        'background-color' : 'White'
    };
}

}
