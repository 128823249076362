import { Component, OnInit } from '@angular/core';
import { jobs } from './jobDetail'

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.css']
})
export class QualificationComponent implements OnInit {

  jobs: jobs[] = [{
    comapny: "Tata Consultancy Services (TCS)",
    image: "../../assets/images/tcslogo.png",
    title : "Assistant System Engineer - Trainee",
    desc : `Engineer at TCS:- Worked with ASP .net, JavaScript,
    jQuery, CSS and HTML. My work here implementing new features and solving defects as per client's requirement.
    Total Experience:- 1 year`,
    emoji: " ☁️",
    website: "https://tcs.com"
  },
  {
    comapny: "Rootsapp",
    image: "../../assets/images/rootsapp.svg",
    title : "Backend Development and Security Head",
    desc : `Worked at a startup named Rootsapp which was
    founded by a friend of mine (<a target='_blank' href='https://www.linkedin.com/in/sahaj-rana/'>Sahaj Rana</a>). Worked with Angular,
    AWS, security of data transmission, APIs and DevOps.
    Total Experience:- 8 months (informal).`,
    emoji: " 🚀",
    website: "https://rootsapp.in"
  },
  {
    comapny: "Face Recognition based Attendance System",
    image: "../../assets/images/facerek.jpg",
    title : "College Side Project made with <a target='_blank' href='https://www.linkedin.com/in/darpan-malik-382a16151/'>Darpan Malik</a>",
    desc : `It uses AWS's Rekognition, S3, lambda and other services to recognize faces and stroing the information in database.
    Locally it uses CV2 to detect faces. Darpan Malik has contributed heavily with me in this project and it was his brain child.`,
    emoji: " 🔎",
    website: "https://github.com/harsh594/Face_recognition_aws_python"
  },
  {
    comapny: "Anomaly Detection",
    image: "../../assets/images/anomaly.png",
    title : "Detection of anomalies made with <a target='_blank' href='https://www.linkedin.com/in/darpan-malik-382a16151/'>Darpan Malik</a>",
    desc : `It uses ImageAI model to detect most of the objects. We made this project for a competition where the dataset was in form
    of images captured by a CCTV on a sidewalk. We analyzed which image has anomaly(cars, bikes, trucks, people walking on grass etc.) in real time.`,
    emoji: " 🔎",
    website: "https://github.com/harsh594/Anomalies_AI"
  },
  {
    comapny: "Meerut Institute of Engineering & Technology",
    image: "../../assets/images/miet.png",
    title : "Computer Science and Engineering",
    desc : `I am grateful to my college for shaping me in who I am today and the 4 year degree in Bachelors of Technology (B.Tech),
    Computer Science as a Major helped me a lot to hone my skills and bring out the best of me.`,
    emoji: " 🤓",
    website: "https://www.miet.ac.in"
  }
]

  constructor() { }

  ngOnInit() {
  }

}
