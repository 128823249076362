import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-principles',
  templateUrl: './principles.component.html',
  styleUrls: ['./principles.component.css']
})
export class PrinciplesComponent implements OnInit {
  principle:any = ['Hold on, do not let go', 'Passion > Paychecks', 'There is always a way', 'Whine less, do more', 'Be rude rather than a fool',
   'Keep your promises', 'Learn while you can', 'Be Lazy', 'Find a way where no one else can'];

  constructor() { }


  ngOnInit() {
  }

}
