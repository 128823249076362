import { Component, OnInit } from '@angular/core';
import { quesAns } from './questionInterface'

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent implements OnInit {

  qaList: quesAns[] = [{
    id: 1,
    question: "What do I do?",
    answer: "currently I work at TCS as a junior FullStack Developer and my work mostly revolves around C#, ASP .Net, JavaScript, CSS and HTML.",
    class: "odd"
  },
  {
    id: 2,
    question: "What do i like about a person?",
    answer: `their eyelashes, I guess. Desire to learn and their willingness to tackle challenges that are above their pay grade.`,
    class: "even"
  },
  {
    id: 3,
    question: "What do I want to do?",
    answer: `i am really passionate about AWS and have worked with it in the past,
    i have done some small projects on data science and would like to
    find a job that helps me sharpen my skills (I am a quick learner :D).`,
    class: "odd"
  }
  ]

  constructor() { }

  ngOnInit() {
  }

}
