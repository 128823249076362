import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  backHeadColor = 'black';
  textHeadColor = 'white';
  scrolled = false;
  image = '../../assets/images/BlackLogo.jpg';
  @HostListener('window:scroll', ['$event'])
  doSomething(event) {
    if (this.router.url === '/') {
      if (window.pageYOffset >= 50) {
        this.scrolled = true;
        this.image = '../../assets/images/WhiteLogo.jpg'
      }
      else {
        this.scrolled = false;
        this.image = '../../assets/images/BlackLogo.jpg'
      }
      return;
    }
    if (this.router.url == '/blog') {
      this.image = '../../assets/images/BlackLogo.jpg'
      return;
    }
    if(this.router.url == '/hire'){
      this.image = '../../assets/images/WhiteLogo.jpg'
    }
    else{
      this.scrolled = true;
      this.image = '../../assets/images/WhiteLogo.jpg'
    }
  }


  constructor(private router: Router) { }

  ngOnInit() {
    if (this.router.url === '/hire') {
      this.scrolled = true;
      this.image = '../../assets/images/WhiteLogo.jpg'
    }
    else if (this.router.url === '/blog') {
      this.scrolled = false;
      this.image = '../../assets/images/BlackLogo.jpg'
    }
    else if (this.router.url !== '/blog' && this.router.url !== '/hire' && this.router.url !== '/') {
      this.scrolled = true;
      this.image = '../../assets/images/WhiteLogo.jpg'
    }
  }

}
