import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainBodyComponent } from '../app/main-body/main-body.component'
import { HiringComponent } from '../app/hiring/hiring.component';
import { BlogComponent } from '../app/blog/blog.component';
import { ErrorComponent } from '../app/error/error.component';


const routes: Routes = [
  { path: '', component: MainBodyComponent },
  { path: 'hire', component: HiringComponent },
  { path: 'blog', component: BlogComponent },
  { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
